@import "./../../../styles/variables";

.topic__items {
  max-height: 85vh !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    // &-track {
    //   // background: #dad8d8;
    // }
    // &-thumb {
    //   background: rgba(136, 136, 136, 0.541);
    //   border-radius: 20px;
    //   &:hover {
    //     background: rgba(85, 85, 85, 0.568);
    //   }
    // }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.topic-item {
  background-color: #fff;
  min-height: 332px;
  border-radius: 12px;
  padding: 0 !important;
  cursor: pointer;
  &.proposed-topics{
    min-height: 300px;
    .info{
      margin: auto;
      padding-top: 35px;
    }
  }
  .ant-btn.ant-btn-text.popover-btn {
    position: absolute;
    right: 5%;
    top: 5%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 37px;
    border-radius: 4px;
    span {
      margin-top: 5px;
      font-size: 1.6rem;
      color: #fff;
    }
  }
  .header {
    img {
      // animation: 1s ease-in-out 0s 1 fadeIn;
      width: 100%;
      height: 100%;
      min-height: 170px;
      max-height: 170px;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }
  }
  .info {
    padding: 15px 30px;
    .tests-badge {
      background-color: $menu-item-active;
      border-radius: 13px;
      width: 74px;
      color: #fff;
      padding: 2px 10px;
    }
    .title {
      min-height: 48px;
      text-align: left;
      font: normal normal 600 16px/24px Proxima Nova;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .new-questions {
      color: $primary-color;
    }
  }
}
