@import './../../styles/variables';

.profile__view{
    &-header{}
    &-form{
        height: 85vh;
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 50px;
        .help__form{
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            .fields{
                input{
                    font-size: 1rem;
                    width: 40%;
                }
                >*{
                    margin-bottom: 45px;
                }
                span{
                    .ant-upload-list.ant-upload-list-text{
                        margin-bottom: 50px;
                        width: max-content;
                    }
                    .ant-upload-text{
                        // padding: 50px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 144px;
                            height: 144px;
                        background-color: #ccc;
                        border-radius: 50%;
                        overflow: hidden;
                        img{
                            width: 144px;
                            height: 144px;
                            object-fit: cover;
                        }
                        .footer{
                            position: absolute;
                            background-color: #000;
                            height: 25%;
                            width: 100%;
                            bottom: 0;
                            opacity: .82;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span{
                                color: #fff;
                                font-size: 1rem;
                            }
                            &:hover{
                                transition: all .25s ;
                                height: 100%;
                            }
                        }
                    }
                    .image-footer{
                        position: fixed;
                        // margin-left: -50px;
                        // padding: 50px;
                        // background-color: #000;
                    }
                }
            }
            .controls{
                display: flex;
                justify-content: flex-end;
                button{
                    padding: 3px 32px;
                }
            }
        }
    }
}