@import './../../../../styles/variables';

.test__view {
  &-banner {
    background: transparent linear-gradient(180deg, #F08D47 0%, #EEA849 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    height: 120px;
    border-radius: 12px;
    padding: 25px 30px;
    margin-bottom: 20px;
    position: relative;
    *{
        color: #fff;
    }
    .title__container{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        .title{
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            span{
                font-size: 18px;
            }
            >*{
                margin-right: 12px;
                cursor: pointer;
            }
            h1{
                font: normal normal bold 24px/28px Proxima Nova;
            }
        }
        .info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            margin-left: 30px;
        }
    }
    .footer{
      width: 300px;
        position: absolute;
        right: 0;
        bottom: 10%;
        ul{
          .ant-menu-item-selected::after,.ant-menu-item:hover::after, .ant-menu-submenu:hover::after, .ant-menu-item-active::after, .ant-menu-submenu-active::after, .ant-menu-item-open::after, .ant-menu-submenu-open::after, .ant-menu-item-selected::after, .ant-menu-submenu-selected::after{
            border-bottom: 2px solid #fff !important;
          }
            background-color: transparent !important;
            li{
              height: 35px;
            }
        }
    }
  }
  &-numbers {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    > div {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 3px 24px #00000005;
      border-radius: 8px;
      &:not(:last-child) {
        margin-right: 0px;
      }
      h1 {
        font-size: 40px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        color: #4f4f4f;
      }
    }
  }
  &-table {
      height: 100% !important;
      table{
          tr:not(.ant-table-placeholder){
            td{
              &:last-child{
                display: flex;
                align-items: center;
                justify-content: space-around;
                div{
                  color: $menu-item-active;
                }
              }
            }
          }
      }
  }
}

.test__view-table .ant-table-body table colgroup{
    min-width: 290px !important;
}

.test__view-table .ant-table-body table {
    padding-bottom: 10px !important;
}

p.report{
  margin-bottom: 0;
}