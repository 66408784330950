@import "../../styles/variables";
@import "../../styles/helpers";

.topics-list {
  &__menubar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .topics-menubar {
      .ant-menu {
        padding: 0 10px;
        width: 265px !important;
        background: transparent
          linear-gradient(180deg, #f08d4736 0%, #eea94923 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 30px;
        &-item {
          height: 44px !important;
        }
      }
    }
    .ant-tabs-nav {
      margin: 0 !important;
    }
    .create-topic {
      button {
        padding: 0 35px;
      }
    }
  }
  &__container {
    margin-top: 30px;
    .topics-list__search-bar {
      .search-box {
        height: 85vh;
        background-color: #fff;
        border-radius: 10px;
        padding: 30px 25px;
        padding-bottom: 0;
        .ant-input {
          border: 1px solid $border-color;
          border-right: none !important;
          padding: 8px 14px;
          border-radius: 10px 0 0 10px;
          color: $input-color !important;
          &:active,
          &:hover,
          &:focus {
            box-shadow: none;
            border-color: $border-color;
          }
        }
        .list-languages {
            .ant-table{
                color: #4f4f4f;
            }
          .ant-table-header {
            display: none;
          }
          .ant-table-body {
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 8px;
              &-track {
                // background: #dad8d8;
              }
              &-thumb {
                background: #f2f2f2 0% 0% no-repeat padding-box;
                border: 1px solid #00000000;
                border-radius: 12px;
              }
            }
            table {
              margin-top: 10px;
              tr {
                &:first-child {
                  display: none;
                }
                td {
                  border: none;
                  padding: 15px 5px !important;
                }
              }
            }
          }
        }
      }
    }
    .topics-list__items {
      .topic-items {
      }
    }
  }
}
.ant-menu-item {
  border-radius: 4px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 3px solid $primary-color !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #000 !important;
  font-weight: 800;
  font-family: ProximaNovaBold !important;
}
