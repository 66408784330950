@import './../../../styles/variables';

.proposed__topic{
    &-header{
        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div{
                &:first-child{
                    >*{
                        margin-right: 15px;
                    }
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                }
                &:last-child{
                    p{
                        font-size: 14px;
                        color: $primary-color;
                        span{
                            color: #000;
                            font-weight: 600;
                        }
                    }
                }
            }
            span,h1{
                font-weight: 600 !important;
            }
            h1{
                font-size: 1.5rem;
            }
            >span{
                font-size: 1.2rem;
            }
            button{
                cursor: default;
                height: 30px !important;
            }
        }
    }
    &-content{
        position: relative;
        border-radius: 8px;
        height: 82vh;
        max-width: 100vw;
        background-color: #fff;
        padding: 50px 60px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
        .edit-icon {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            i{
                font-size: 1.2rem;
                color: $primary-color;
            }
        }
        .texts{
            h3{
                margin-bottom: 0;
                font-size: 1rem;
                display: flex;
                align-items: center;
            }
        }
        .image{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-direction: column;
            img{
                border-radius: 12px;
            }
            .actions{
                button{
                    padding: 0 35px;                    
                    &:last-child{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}