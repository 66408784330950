@import './../../../../styles/variables';

.test__form{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    .ant-select{
        .ant-select-selector{
            border: none !important;
            border-bottom: 1px solid #d9d9d9 !important;
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
            &:active,&:hover,&:focus{
                box-shadow: none;
                border: none;
                border-bottom:1px solid $input-color;
            }
        }
        >div{
            width: 100% !important;
        }
    }
    &-fields{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 40px;
        >div{
            margin-bottom: 28px;
            label{
                font-size: 16px;
            }
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            input{
                &::placeholder{
                    font-weight: 400;
                }
                font-weight: 600;
                font-size: 16px;
                color: #000 !important;
            }
            &:nth-child(7){
                &::after{
                    content: '(Optimal number of questions - 75 questions with 3 options and 15 seconds per question)';
                }
            }
            &:nth-child(8){
                justify-content: flex-start;
            }
        }
    }
    &-actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button{
            padding:5px 30px;
        }
    }
}

.success-modal{
    width: 562px !important;
    .ant-modal-header{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 166px;
        background: transparent linear-gradient(180deg, #F08D47 0%, #EEA849 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        .ant-modal-title{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            i{
                background-color: #fff;
                border-radius: 50%;
                padding: 20px;
                color: $primary-color;
                margin-top: 25px;
                margin-bottom: 15px;
            }
            h2{
                color: #fff;
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }
    .ant-modal-content{
        text-align: center;
        height: 281px;
        border-radius: 18px;
        overflow: hidden;
        button{
            color: #fff;
            span{
                font-size: 20px;
            }
        }
    }
    .ant-modal-body{
        h3{
            font-size: 18px;
        }
    }
}