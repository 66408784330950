.approved__questions {
  height: 69vh;
  p{
    margin-bottom: 0;
  }
  table {
    tr:not(.ant-table-placeholder){
      &.ant-table-row {
        td:last-child {
          //   display: flex;
          //   align-items: center;
          //   justify-content: flex-start;
          div {
            margin-bottom: 0;
            //   padding: 11px 18px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-content: space-between;
            p {
              margin-bottom: 0;
            }
            span {
              margin-left: 10px;
              display: none;
              button {
                &.ant-switch-checked {
                  background-color: #40ce2a;
                }
              }
            }
          }
        }
      }
      &:hover {
        &.ant-table-row {
          td:last-child {
            div {
              p {
              }
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.questions__list {
  tr {
    td,
    th {
      &:nth-child(5) {
        text-align: center;
      }
    }
  }
}
