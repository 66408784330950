@import './../../styles/variables';

.users__list{
    &-header{

    }
    &-table{
        height: 100% !important;
        // margin-top: 10px;
        .ant-table-container{
            padding: 15px 40px !important; 
        }
        .ant-table-tbody,.ant-table-thead{
            tr{
                td,th{
                    &:nth-child(3),&:nth-child(4),&:nth-child(5){
                        text-align: center;
                    }
                }
            }
        }
    }
}

.users__list-table .ant-table-body table colgroup{
    min-width: 250px !important;
}