@import "./../../../../styles/variables";

.certificate__view {
  user-select:none;
  &-header {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    p {
      margin-left: 8px;
    }
  }
  &-card {
    background-color: #fff;
    height: 90vh;
    width: 100%;
    position: relative;
    &-header {
      * {
        color: #fff;
      }
      height: 296px;
      background: transparent linear-gradient(180deg, #f08d47 0%, #eea849 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
      width: 100%;
      padding: 40px;
      .banner {
        text-align: center;
        height: 100%;
        h1 {
          display: block;
          font: normal normal bold 72px/24px Roboto;
          letter-spacing: 14.4px;
          color: #ffffff;
          text-transform: uppercase;
          opacity: 1;
        }
        h3 {
          margin-bottom: 55px;
        }
        i {
          font-size: 2.5rem;
        }
        h2 {
          font: normal normal 300 38px/24px Roboto;
          letter-spacing: 22.8px;
          color: #ffffff;
          text-transform: uppercase;
          opacity: 1;
        }
      }
      .certificate-number {
        position: absolute;
        right: 1%;
        top: 2%;
      }
    }
    &-info {
      padding: 40px;
      padding-bottom: 10px;
      margin: 0 auto;
      text-align: center;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 24px #00000005;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .banner {
          >p{
              color: #4F4F4F;
              font-size: 18px;
          }
          h3{
              font-size: 1.5rem;
              font-weight: bold;
              text-transform: capitalize;
          }
          h2{
              color: $primary-color;
              font-size: 32px;
              font-weight: bolder;
              text-transform: capitalize;
          }
          >div{
              display: flex;
              align-items: baseline;
              justify-content: center;
              color: #40CE2A;
              i{
                  color: #fff;
                background-color: #40CE2A;
                  padding: 8px;
                  border-radius: 50%;
                  margin-right: 10px;
              }
              p{
                font-size: 1rem;
              }

          }
      }
      .signatures {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .date,.sign {
            h3{
                font-weight: 600;
            }
        }
        .badge {
          img {
            width: 132px;
            height: 136px;
          }
        }
        .sign {
          h3{
            font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
          }
        }
      }
    }
  }
}
