@import "../../../../styles/variables";

.all__questions {
  display: grid;
  grid-template-columns: 40% 58%;
  grid-gap: 20px;
  .questions__list,
  .question__view {
    background-color: #fff;
    border-radius: 12px;
    height: 69vh;
    padding: 35px;
    overflow: hidden;
  }

  .questions__list {
    p,
    .ant-typography.ant-typography-ellipsis.ant-typography-single-line.ant-typography-ellipsis-single-line {
      min-width: 50px;
      overflow: ellipsis;
      font-size: 15px;
      line-height: 28px;
      margin-bottom: 0 !important;
      &.bold {
        font: normal normal bold 15px/28px ProximaNovaBold;
      }
    }
  }
  .question__view {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none !important;
    }
    p {
      color: #7e7e7e;
      font-size: 1rem;
      margin-bottom: 0;
    }
    &-question {
      img {
        max-width: 100%;
        object-fit: contain;
        margin: 18px 0;
        border-radius: 12px;
        max-height: 380px;
      }
      .question-text {
        font-size: 17px;
        color: #4f4f4f;
      }
      p{
        margin: 0;
      }
    }
    &-answer {
      margin-top: 10px;
      max-width: 80%;
      .correct_answer_image{
        height: 200px;
        max-width: 350px;
      }
      .answers_grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
        .answer_item{
          border-radius: 8px;
          padding: 20px;
          border: 1px solid rgba(128, 128, 128, 0.253);
          img{
            max-width: 240px;
            max-height: 185px;
          }
        }
      }
      .answer_item{
        h3{
          margin: 0;
        }
      }
      img {
        max-width: 100%;
      }
      > h3 {
        margin-bottom: 0;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        span{
            margin-right: 5px;
        }
        span {
          color: #40ce2a;
          margin-bottom: 8px;
          * {
            margin-right: 5px !important;
          }
        }
      }
    }
  }
  .ant-input-group-wrapper {
    width: 75%;
  }
  .ant-input {
    border: 1px solid $border-color;
    border-right: none !important;
    padding: 8px 14px;
    border-radius: 10px 0 0 10px;
    color: $input-color;
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      border-color: $border-color;
    }
  }
  .ant-table-header {
    display: none;
  }
  .ant-table-body {
    overflow-y: scroll;
    padding-bottom: 80px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    table {
      margin-top: 10px;
      overflow-y: hidden;
      colgroup {
        width: 42px;
      }
      tr {
        &:first-child {
          display: none;
        }
        td {
          padding: 20px 5px !important;
        }
      }
    }
  }
}

//
