.submitted__question-table{
    height: 100% !important;
    p.status{
        padding: 5px 18px;
        text-align: center;
        width: max-content;
        margin-top: 10px;
        border-radius: 8px;
        text-transform: capitalize;
        &.approved{
            background-color: #DFFCE7;
            color: #25BD4F;
        }
        &.rejected{
            background-color: #FCDFDF;
            color: #E93333;
        }
        &.pending_approval{
            background-color: #DFF2FC;
            color: #1AA1E9;
        }
    }
    .ant-table-tbody td{
        padding: 0px 8px !important;
    }
    .ant-table-body table {
        padding-bottom: 10px !important;
    }
}