@import "../../../styles/variables";
@import '../../../styles/_icons.scss';
.app-sidebar {
    width: 150px;
    background-color: #ffffff;
    box-shadow: 0px 3px 24px #00000005;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    // padding: 1rem 0;
    z-index: 1001 !important;
    padding: 0 !important;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    .ant-menu{
        color: #6A6A6A !important;
    }

    .ant-menu-item {
        padding: 2.3rem 24px !important;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        // height: auto;
        text-align: center;
        margin-bottom: 12px !important;
        margin-top: 0 !important;
        line-height: 22px !important;
        &:hover {
            color: $primary-black;
        }

        i {
            font-size: 1.4rem;
        }

        .app-sidebar__menu-title {
            line-height: 1rem;
        }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #ffffff;
        // box-shadow: $secondary-shadow;
        border-left: 4px solid $menu-item-active;
        color:$menu-item-active;
        font: normal normal bold 14px/38px ProximaNovaBold;
        background-color: #F6F9FE;
        width: 100%;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border: none;
    }

    #profile-link{
        bottom: 10px;
        position: fixed;
        margin: auto !important;
        background-color: blanchedalmond !important;
        margin: 10px 10px 0 10px !important;
        margin-top: 35px !important;
        width: 130px;
        background: transparent linear-gradient(180deg, #F08D47 0%, #EEA849 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px !important;
        height: 100% !important;
        padding: 10px 2px !important;
        color: #fff !important;
        border: none !important;
        height: 121px !important;
    }
    .popover-btn{
        position: absolute;
        top: 3%;
        right: 2%;
        color: #fff;
        font-weight: bolder;
        background-color: rgba(255, 255, 255, 0.19);
        border-radius: 3px;
        padding: 1px 10px !important;
        height: 20px;
        z-index: 10;
        &:hover,&:active,&:focus{
            color: #fff;
        }
    }
}

.app-sidebar__logo {
    // position: absolute;
    // bottom: 1rem;
    margin: 20px auto 0 auto;
    width: 70%;
    display: block;
    // left: 0;
    // right: 0;
}
.sidebar__logo {
    height: 40px !important;
    width: 40px !important;
}
.app-sidebar__brand-logo {
    object-fit: none;
    display: block;
    width: 6rem;
    margin: 15px auto 15px auto;
    border-radius: 10px;
    cursor: pointer;
}

.app-sidebar__container {
    img {
        height: 40px !important;
        width: 40px !important;
    }
}

.app-sidebar::-webkit-scrollbar {
    display: none;
}


.popover-list{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li:not(:last-child){
        margin: 0 0 8px 0;
    }
    li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color:#4F4F4F;
        font-size: .9rem;
        i{
            font-size: 1.08rem !important;
            margin-right: 8px;
        }
    }
}

.admin-badge{
    // height: 20px;
    padding: 5px;
    border-radius: 20px;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.19);
    margin: auto;
    margin-top: 4px;
    font-weight: 400;
}

.ant-menu-title-content{
    img{
        border-radius: 50%;
        margin-bottom: 4px;
        margin-top: 5px;
    }
}