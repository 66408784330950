@import './../../../../styles/variables';

.pending-approval__questions,.approved__questions,.questions__list-table,.hiring-tests__list-table,.users__list-table,.test__view-table,.manage__question-table,.test__user-table,.attempted__test-table,.submitted__question-table,.test__user__tags-table{
height: 69vh;
border-radius: 8px !important;
overflow: hidden !important;
    .ant-table-wrapper{
        border-radius: 8px !important;
        overflow: hidden !important;
    }
    .ant-table-container{
        padding: 20px 35px;
    }
    .ant-table-header{
        thead{
            tr{
                th{
                    background-color: #fff !important;
                    font-weight: 700;
                }
            }
        }
    }
    .ant-table-body{
        overflow-y: scroll;
        &::-webkit-scrollbar{
            width: 10px;
        }
        table{
            // padding-bottom: 80px;
            colgroup{
                width: 120px;
            }
            tr{
                td{
                    // padding: 20px 5px !important;
                }
            }
        }
    }
}

.questions__list-table,.users__list-table{
    max-height: 100% !important;
    .ant-table-wrapper{
        border-radius: 8px !important;
        overflow: hidden !important;
    }
    .ant-table-container{
        padding: 18px;
    }
    .ant-table-header{
        thead{
            tr{
                th{
                    background-color: #fff !important;
                    font-weight: 700;
                }
            }
        }
    }
    .ant-table-body{
        overflow-y: scroll;
        &::-webkit-scrollbar{
            width: 10px;
        }
        table{
            padding-bottom: 0px !important;
            colgroup{
                width: 120px;
            }
            tr{
                td{
                    // padding: 20px 5px !important;
                }
            }
        }
    }
}

.pending-approval__questions,.approved__questions{
    height: 100% !important;
}