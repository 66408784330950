@import "./../../styles/variables";
.auth-container {
  background-color: #f8f7f4;
  color: $input-color;
  height: 100vh;
  display: grid;
  grid-template-rows: 42% 58%;
  &__header {
    padding: 50px 0 25px 114px;
    background: transparent linear-gradient(124deg, #f08d47 0%, #eea849 100%) 0%
      0% no-repeat padding-box;
    * {
      color: #fff;
    }
    h1 {
      font-family: ProximaNovaBold !important;
      font-size: 2.5rem;
      width: 640px;
    }
    h3 {
      font-family: ProximaNovaRegular !important;
      margin-bottom: 20px;
      font-size: 1.5rem;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    .image__wrapper {
      img {
        margin-left: 20px;
        width: 50vw;
        height: 319px;
        height: 48vh;
        object-fit: contain;
      }
    }
    .form__wrapper {
      margin: auto;
      transform: translateY(-150px);
      overflow: hidden;
      height: 400px;
      width: 433px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 12px 24px #0000000f;
      border-radius: 12px;
      padding: 61px 57px 67px;
      color: $input-color;
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        input::placeholder {
          color: $input-color;
        }
        > *:not(:last-child) {
          margin-bottom: 23px;
        }
        &.email__form,
        &.verify__form {
          > div {
            margin: 20px 0 60px 0;
          }
        }
        &.verify__form {
          .otp-container {
            padding: 3px 0;
            margin-left: 50px;
            margin-right: 50px;
            align-items: center;
            justify-content: space-between;
          }
          .otp-input {
            width: 2.5rem !important;
            outline: none;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #b2b4b5 !important;
            font-size: 1.8rem;
            color: #000;
            &:focus {
              outline: none !important;
            }
          }
        }
        &.reset__form {
          > * {
            margin-bottom: 35px;
          }
        }
        &.login__form {
          div {
            &:nth-child(3) {
              margin-bottom: 0;
            }
          }
        }
        .ant-btn-text {
          text-align: right;
          padding-right: 0;
          margin:0 0 23px auto;
        }
        .form-header {
          font-size: 2rem;
          font-weight: 600;
          &.login {
            color: $primary-color;
          }
          &.reset,
          &.verify,
          &.email {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
