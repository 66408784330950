@import './../../../../styles/variables';

.all__tests{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    &-card{
        width: 100%;
        height: 130px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 24px #00000005;
        border-radius: 8px;
        padding: 26px 33px;
        h2{
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            i{
                color: $primary-color;
            }
        }
        p{
            color: $menu-item-active;
        }
    }
}