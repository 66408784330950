@import './../../styles/variables';

.tutorial__view{
    &-header{}
    &__questions{
        display: grid;
        grid-gap: 13px;
        grid-template-columns: repeat(3,1fr);
        &-question{
            position: relative;
            background-color: #fff;
            padding: 50px 30px 36px 42px;
            border-radius: 8px;
            cursor: pointer;
            h3{
                font-size: 1rem;
                font-weight: 600;
                margin-top: 25px;
                margin-bottom: 1px;
            }
            >i{
                color: $primary-color;
                font-size: 7rem;
            }
            p{
                font-size: 14px;
                color: #4F4F4F;
            }
            >div{
                position: absolute;
                right: 5%;
                top: 5%;
                padding: 8px 11px;
                background-color: #f08d473a;
                border-radius: 50%;
                display: none;
                cursor: pointer;
                z-index: 9999;
                i{
                    color: $primary-color;
                }
            }
            &:hover{
                >div{
                    display: block;
                }
            }
        }
    }
}