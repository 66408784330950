.attempted__test-table{
    height: 100% !important;
    .ant-table-tbody,.ant-table-thead{
        tr{
            // >th{
            //     padding: 12px 0px !important;
            // }
            td,th{
                &:nth-child(3){
                    text-align: center;
                }
            }
        }
    }
    .ant-table-container{
        padding: 18px 65px;
    }
    p.status{
        padding: 4px 18px;
        text-align: center;
        width: max-content;
        margin-top: 10px;
        border-radius: 8px;
        text-transform: capitalize;
        &.active{
            background-color: #DFFCE7;
            color: #25BD4F;
        }
        &.expired{
            background-color: #FCDFDF;
            color: #E93333;
        }
        &.not_deployed{
            background-color: #DFF2FC;
            color: #1AA1E9;
        }
    }
    .ant-table-tbody td{
        padding: 0 8px !important;
    }

    .ant-table-body table {
        padding-bottom: 10px !important;
        colgroup{
            min-width: 350px !important;
        }
    }
}

