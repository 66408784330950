@import "./../../styles/variables";

.questions__list {
  &-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 30px;
      i {
        margin-right: 10px;
      }
    }
  }
  &-table {
    .ant-table-container{
      padding: 18px 30px !important;
    }
    .ant-table-tbody {
      td {
        padding: 5px !important;
      }
    }
    .ant-table-thead {
      tr th {
        padding:12px 5px !important;
      }
    }
    p.status {
      padding: 5px 18px;
      text-align: center;
      width: max-content;
      margin-top: 10px;
      border-radius: 8px;
      text-transform: capitalize;
      &.approved {
        background-color: #dffce7;
        color: #25bd4f;
      }
      &.rejected {
        background-color: #fcdfdf;
        color: #e93333;
      }
      &.pending_approval {
        background-color: #dff2fc;
        color: #1aa1e9;
      }
    }
  }
}
.filter-drawer {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      padding: 25px;
    }
  }
  .ant-drawer-body {
    height: 100%;
    &::-webkit-scrollbar {
      width: 8px;
      &-track {
        // background: #dad8d8;
      }
      &-thumb {
        background: #f2f2f2 0% 0% no-repeat padding-box;
        border: 1px solid #00000000;
        border-radius: 12px;
      }
    }
    form {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      .ant-col {
        h3 {
          font-size: 14px;
          color: #9e9e9e;
        }
        .ant-checkbox-wrapper {
          font-size: 15px;
          color: #4f4f4f;
        }
      }
    }
  }
  .ant-checkbox-group {
    display: flex !important;
    flex-direction: column !important;
  }
  .anticon-close {
    font-size: 22px;
    font-weight: bolder;
    color: #000;
    padding-bottom: 15px;
  }
  .filter-controller {
    button {
      padding: 0 30px;
    }
  }
}

.questions__list .all_questions tr {
  th:nth-child(5) {
    text-align: left;
  }
  td:nth-child(5) {
    text-align: left;
  }
}

.ant-typography.ant-typography-ellipsis.ant-typography-single-line.ant-typography-ellipsis-single-line {
  min-width: 50px;
  overflow: ellipsis;
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0 !important;
}

.sorting-title{
  display: flex;
  align-items: center;
  img{
    margin-left: 12px;
  }
}