@import './../../../../styles/variables';

.deployed__tests{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    width: 100%;
    &-card{
        position: relative;
        width: 100%;
        height: 130px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 24px #00000005;
        border-radius: 8px;
        padding: 20px 33px;
        h2{
            min-height: 40px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font: normal normal 600 18px/24px ProximaNova;
            i{
                color: $primary-color;
                position: absolute;
                right: 20px;
                top: 20px;
                &::before{
                    font-size: 23px;
                }
            }
        }
        p{
            color: $menu-item-active;
            color: $primary-color;
            margin-bottom: 0;
            
            &.certificate{
                color: #4F4F4F;
            }
            span{
                margin-right: 10px;
                padding: 5px 10px;
                color: #fff;
                border-radius: 20px;
                &.custom{
                    background-color: $primary-color;
                }
                &.certificate{
                    background-color: $menu-item-active;
                }
            }
        }
    }
}