@import "./../../../styles/variables";

.help__view {
  &-header {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    h2{
        font-weight: 600;
    }
    span {
      padding-right: 15px;
    }
  }
  &-content {
      background-color: #fff;
    box-shadow: 0px 3px 24px #00000005;
    border-radius: 8px;
    height: 85vh;
    width: 100%;
    padding: 30px;
    .image{
        background-color: $primary-color;
        width: 100%;
        height: 327px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        margin-bottom: 30px;
        i{
            color: #fff;
            font-size: 6rem;
        }
    }
    p{
        color:#4F4F4F;
        font-size: 1rem;
    }
    .help__form{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: column;
        height: 43%;
        button{
            padding: 2px 35px;
        }
    }
  }
}
