@import "./../../../styles/variables";

.topic{
    &__banner{
        background-color: $primary-color;
        width: 100%;
        height: max-content;
        border-radius: 12px;
        padding: 25px 30px;
        margin-bottom: 20px;
        *{

            color: #fff !important;
        }
        .title__container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                >*{
                    margin-right: 12px;
                    cursor: pointer;
                }
                span{
                    font-size: 1.2rem;
                }
                i{
                    font-size: 1.1rem;
                }
                h1{
                    font: normal normal bold 24px/28px Proxima Nova;
                }
            }
            .actions{
                button{
                    background-color: $menu-item-active !important;
                    margin-right: 10px;
                    padding: 0 21px;
                }
            }
        }
        .footer{
            .ant-typography.ant-typography-ellipsis.ant-typography-single-line{
                white-space: unset;
                margin-bottom: 0;
            }
            .ant-typography-expand{
                text-decoration: underline;
            }
            margin-left: 35px;
            max-width: 77%;
        }
    }
    &__menu-bar{
        .topics-menubar{
            // width: 550px;
        }
    }
    &__questions{
        .questions__search{}
        .questions__view{}
    }
}