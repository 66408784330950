@import "./../../../styles/variables";

.user__profile {
  &-header {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin-bottom: 10px;
    span {
      margin-right: 15px;
      color: #000;
    }
    h2 {
      text-align: left;
      color: #000000;
      font-weight: 600;
    }
  }
  &-numbers {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 15px;
    > div {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 3px 24px #00000005;
      border-radius: 8px;
      &:not(:last-child) {
        margin-right: 0px;
      }
      h1 {
        font-size: 40px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        color: #4f4f4f;
      }
    }
  }
  &-tests {
    .ant-tabs.ant-tabs-top{
        .ant-tabs-nav{
            // width: 100% !important;
        }
        .ant-tabs-content-holder{}
    }
  }
}
