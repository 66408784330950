.test__user{
    &-header{
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        h1{
            margin-left: 15px;
        }
    }
}

.test__user-table .ant-table-body table colgroup{
    min-width: 580px !important;
}

.test__user-table .ant-table-body table,.test__user__tags-table .ant-table-body table {
    padding-bottom: 10px !important;
    // margin-top: 15px;
        .ant-table-tbody{
            td{
                // padding: 5px !important;
            }
        }
        p.status{
            margin: 0 !important;
            padding: 5px 18px;
            text-align: center;
            width: max-content;
            margin-top: 10px;
            border-radius: 8px;
            text-transform: capitalize;
            &.correct{
                background-color: #DFFCE7;
                color: #25BD4F;
            }
            &.wrong{
                background-color: #FCDFDF;
                color: #E93333;
            }
            &.skipped{
                background-color: #DFF2FC;
                color: #1AA1E9;
            }
            &.un_attempted{
                background-color: #FCF1DF;
                color: #F08D47;
            }
            &.Correct{
                background-color: #DFFCE7;
                color: #25BD4F;
            }
            &.Wrong{
                background-color: #FCDFDF;
                color: #E93333;
            }
            &.Skipped{
                background-color: #DFF2FC;
                color: #1AA1E9;
            }
            &.Unattempted{
                background-color: #FCF1DF;
                color: #F08D47;
            }
        }
        color: #4F4F4F;
        p.tag{
            padding: 5px 18px;
            text-align: center;
            width: max-content;
            margin-top: 10px;
            border-radius: 8px;
            background-color: #6666661A;
        }
}

.test__user-table{
    height: 100% !important;
    .ant-table-tbody,.ant-table-thead{
        tr{
            td,th{
                &:nth-child(3){
                    text-align: center;
                }
            }
        }
    }
    &.certificate{
        .ant-table-tbody,.ant-table-thead{
            tr{
                td,th{
                    &:nth-child(3){
                        text-align: left !important;
                    }
                }
            }
        }
    }
}

.test__user__questions{
    &-header{
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        h1{
            margin-left: 15px;
        }
    }
}

.test__user__questions-table .ant-table-body table colgroup{
    min-width: 290px !important;
}

.test__user__questions-table .ant-table-body table {
    padding-bottom: 10px !important;
}
