@import "./variables";
@import "./helpers";

.ant-btn {
  border-radius: 26px;
  border: none;
  &::after {
    content: none;
  }
  &-primary {
    font-size: 15px;
    background: $primary-color !important;
    height: 40px !important;
    &:active,
    &:hover,
    &:focus {
      background-color: $primary-color;
    }
  }
  &.danger {
    background-color: #ff7676 !important;
  }
  &.success {
    background-color: #40ce2a !important;
  }
  &-text {
    color: $input-color;
    &:active,
    &:hover,
    &:focus {
      color: $input-color;
      background-color: transparent;
    }
  }
  &.outlined {
    color: $primary-color;
    background-color: #fff !important;
    border: 1px solid $primary-color;
  }
  &[disabled] {
    color: #fff;
    opacity: 0.5;
    &:hover {
      color: #fff;
    }
  }
}

.ant-input,.ant-input-password {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0;
  color: $input-color !important;
  box-shadow: none !important;
  border-color: $border-color !important;
  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    border-color: $input-color;
  }
}

.ant-input-group {
  .ant-input-group-addon {
    background-color: #fff !important;
    border-radius: 0 10px 10px 0 !important;
  }
}

.ant-tabs-nav {
  height: 42px;
  width: max-content !important;
  max-width: 100% !important;
  background: transparent linear-gradient(180deg, #f08d4736 0%, #eea94923 100%)
  0% 0% no-repeat padding-box;
  border-radius: 30px;
  font-size: 16px;
  &-list{
    padding: 0 30px;
  }
  .ant-tabs-tab,
  .ant-tabs-tab-btn {
    &:hover,
    &:focus,
    &:active,
    &:focus-visible,
    &:focus-within {
      color: #000 !important;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    * {
      font-family: ProximaNovaBold !important;
      color: #000 !important;
    }
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: $primary-color;
    // height: 3px;
    // border-radius: 0 0 100px 100px;
    height: 5px;
    border-radius: 0 0 100% 100%;
  }
}

.ant-table-cell {
  &::before {
    background-color: transparent !important;
  }
}

.ant-select-selection-item {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      display: none;
    }
  }
}

.ant-select {
  width: 100%;
  .ant-select-selector {
    text-transform: capitalize;
    padding-left: 0 !important;
    font-size: .9rem;
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid $input-color;
    }
  }
  > div {
    width: 100% !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.ant-modal-wrap {
  z-index: 1003;
  background-color: rgba(0, 0, 0, 0.72);
}
.ant-modal-mask {
  z-index: 1002;
  background: #fff 0% 0% no-repeat padding-box;
}

.ant-menu-horizontal {
  // border: none;
  border-bottom: none;
}

.ant-table-body {
  table {
    tr {
      cursor: pointer;
    }
  }
}

.ant-popover-placement-bottomRight {
  padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: 8px;
  }
}

.ant-table.ant-table-middle.ant-table-fixed-header {
  border-radius: 8px;
}

//   .ant-table-tbody,.ant-table-thead{
//     tr{
//         >th{
//             padding: 12px 0px !important;
//         }
//     }
// }

.ant-radio-checked .ant-radio-inner {
  border-color: $primary-color !important;
  &::after {
    background-color: $primary-color !important;
  }
  &:hover,
  &:active,
  &:focus,
  &::content {
    border-color: $primary-color !important;
  }
}
.ant-radio-input {
  &::selection {
    background-color: $primary-color !important;
  }
}

.answer-row{
}
.ant-radio-inner{
  width: 18px;
  height: 18px;
  margin-right: 6px;
  &::after{
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    width: 26px;
    height: 26px;
    /* margin: auto; */
    left: 3px;
    top: 3px;
  }
}

.ant-radio-checked .ant-radio-inner{
  border-color: $primary-color !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: $primary-color;
}

.ant-radio:hover .ant-radio-inner {
  border-color: $primary-color ;
}