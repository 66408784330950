h1{
    font-size: 1.5rem;
    font-weight: 800 !important;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    span{
        margin-right: 10px;
        font-size: 1rem;
    }
}